var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Daftar Riwayat Penyimpanan"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-size": "sm",
      "label": "Per page",
      "label-for": "perPage"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "10",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Filter",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Tekan enter untuk cari"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getpengirimanBarangs();
      }
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
        _vm.getpengirimanBarangs();
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "small": "",
      "striped": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "items": _vm.pengirimanBarangs,
      "fields": _vm.fieldsasli,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(selesai)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v(" " + _vm._s(data.item.status_ket ? data.item.status_ket.toUpperCase() : "-") + " ")])];
      }
    }, {
      key: "cell(po)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.po ? item.po.no : "po kosong") + " ")];
      }
    }, {
      key: "cell(tanggal_pengiriman)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.humanDate(item.tanggal)) + " ")];
      }
    }, {
      key: "cell(tanggal_invoice)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.humanDate(item.tanggal_invoice)) + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('section', {
          staticClass: "flex item-center"
        }, [_vm.allowUpdate() && item.status == 1 || _vm.allowUpdate() && !_vm.isAdminGudang ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Batalkan Pengiriman',
            expression: "'Batalkan Pengiriman'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.batal(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XCircleIcon"
          }
        })], 1) : _vm._e(), _vm.allowUpdate() && item.status == 0 || _vm.allowUpdate() && !_vm.isAdminGudang ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Ubah',
            expression: "'Ubah'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push("/owner/pengiriman/edit/".concat(item.id));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e(), _vm.allowDelete() && item.status == 0 || _vm.allowUpdate() && !_vm.isAdminGudang ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Hapus',
            expression: "'Hapus'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1) : _vm._e()], 1)];
      }
    }, {
      key: "cell(actionsCabang)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('section', {
          staticClass: "flex item-center"
        })];
      }
    }, {
      key: "row-details",
      fn: function fn(row) {
        return [_c('b-card', [_c('ul', _vm._l(row.item, function (value, key) {
          return _c('li', {
            key: key
          }, [_vm._v(" " + _vm._s(key) + ": " + _vm._s(value) + " ")]);
        }), 0)])];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1), _vm.allowCreate() || _vm.allowUpdate() ? _c('b-modal', {
    attrs: {
      "id": "modal-",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": "",
      "title": "Form ",
      "size": "xl"
    },
    model: {
      value: _vm.showModal,
      callback: function callback($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('validation-observer', {
    ref: "form"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PackageIcon",
      "size": "19"
    }
  }), _c('h6', {
    staticClass: "ml-50 mb-2"
  }, [_vm._v("Penyimpanan Barang")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "8",
      "lg": "8",
      "xl": "8"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "Cari Barang"
    }
  }, [_vm._v("Cari Barang")]), _c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": "Tekan enter untuk cari"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getDataBarangs();
      }
    },
    model: {
      value: _vm.caribarang,
      callback: function callback($$v) {
        _vm.caribarang = $$v;
      },
      expression: "caribarang"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.getDataBarangs();
      }
    }
  }, [_vm._v(" Cari ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_vm.loaded ? _c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPageBarangs,
      "items": _vm.dataBarangs,
      "fields": _vm.fieldsBarangs,
      "sort-by": _vm.sortByBarangs,
      "sort-desc": _vm.sortDescBarangs,
      "sort-direction": _vm.sortDirectionBarangs,
      "filter-included-fields": _vm.filterOnBarangs
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortByBarangs = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortByBarangs = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDescBarangs = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDescBarangs = $event;
      },
      "filtered": _vm.onFilteredBarangs
    },
    scopedSlots: _vm._u([{
      key: "cell(penyimpanan)",
      fn: function fn(data) {
        return [_c('b-form-checkbox', {
          attrs: {
            "value": data.item
          },
          model: {
            value: _vm.pilihbarang,
            callback: function callback($$v) {
              _vm.pilihbarang = $$v;
            },
            expression: "pilihbarang"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function fn(row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(kode)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item ? item.kode : '-') + " ")];
      }
    }, {
      key: "cell(nama)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item ? item.nama : '-') + " ")];
      }
    }, {
      key: "cell(blok)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.blok ? item.blok.blok : '-') + " ")];
      }
    }, {
      key: "cell(palet)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(item.palet ? item.palet.palet : '-') + " ")];
      }
    }, {
      key: "cell(rak)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(item.rak ? item.rak.rak : '-') + " ")];
      }
    }, {
      key: "cell(laci)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(item.laci ? item.laci.laci : '-') + " ")];
      }
    }, {
      key: "cell(stok)",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_vm._v(" " + _vm._s(item.jumlah ? item.jumlah : '-') + " - " + _vm._s(item.konversi_ket) + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_vm._v(" " + _vm._s(item.satuan ? item.satuan.satuan : '-') + " ")];
      }
    }], null, false, 790734989)
  }) : _vm._e()], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('b-card', [_c('form', [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "gudang",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var errors = _ref14.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Pilih Gudang")]), _c('b-form-select', {
          attrs: {
            "options": _vm.dataGudang,
            "required": !_vm.form.gudang_id,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "text"
          },
          model: {
            value: _vm.form.gudang_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "gudang_id", $$v);
            },
            expression: "form.gudang_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2460265998)
  })], 1), _c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "blok",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var errors = _ref15.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Pilih Blok")]), _c('b-form-select', {
          attrs: {
            "options": _vm.blok_id,
            "required": !_vm.form.blok_id,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "text"
          },
          model: {
            value: _vm.form.blok_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "blok_id", $$v);
            },
            expression: "form.blok_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 415995372)
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Pallet")]), _c('b-form-select', {
    attrs: {
      "options": _vm.palet_id,
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.form.palet_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "palet_id", $$v);
      },
      expression: "form.palet_id"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Rak (optional)")]), _c('b-form-select', {
    staticClass: "mb-2",
    attrs: {
      "options": _vm.rak_id,
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.form.rak_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "rak_id", $$v);
      },
      expression: "form.rak_id"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Laci (optional)")]), _c('b-form-select', {
    staticClass: "mb-2",
    attrs: {
      "options": _vm.laci_id,
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.form.laci_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "laci_id", $$v);
      },
      expression: "form.laci_id"
    }
  })], 1), _c('b-form-group', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.addBarang($event);
      }
    }
  }, [_vm._v("Tambah")])], 1)], 1)])], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-table', {
    attrs: {
      "fields": _vm.fieldsAddedItems,
      "bordered": "",
      "striped": "",
      "responsive": "",
      "items": _vm.addedItems
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + " ")];
      }
    }, {
      key: "cell(lokasi)",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [_c('strong', [_vm._v(" " + _vm._s(item.lokasi) + " ")])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(row) {
        return [_c('b-link', {
          staticClass: "text-danger",
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addedItems.splice(row.index, 1);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "size": "24",
            "icon": "XCircleIcon"
          }
        })], 1)];
      }
    }], null, false, 1822751536)
  }), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" Simpan ")])], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }